import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { GestaoAcessoService } from '@core/services/gestao-acesso.service';

export const solicitarDossieGuard: CanActivateFn = () => {
  const gestaoAcesso = inject(GestaoAcessoService);
  return gestaoAcesso.validaAcessos({
    key: 'nomTag',
    value: 'solicitarDossie'
  });
};
